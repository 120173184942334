<!--
 * @Author: your name
 * @Date: 2020-10-12 16:30:41
 * @LastEditTime: 2020-12-02 11:20:07
 * @LastEditors: Please set LastEditors
 * @Description: 自助抄表
 * @FilePath: \nethallofficialaccounts\src\views\SelfMeterRead\index.vue
-->
<template>
    <div class="self">
        <self-card :dataInfoArr="dataInfo" v-if="isBind"/>
        <no-data v-else />
    </div>
</template>

<script>
import SelfCard from './module/selfCard';
import { selfMeterReadInfo } from '../../api/home';
import NoData from '@/components/noData.vue';
import store from '@/store';

export default {
    name: 'SelfMeterRead',
    components: {
        SelfCard,
        NoData
    },
    data() {
        return {
            dataInfo: [],
            isBind: store.getters && store.getters.homeInfo && store.getters.homeInfo.isBind
        };
    },
    mounted() {
        if (store.getters && store.getters.homeInfo && store.getters.homeInfo.isBind) {
            this.selfMeterReadInfo()
        }
    },
    methods: {
        async selfMeterReadInfo() {
            const {status, resultData} = await selfMeterReadInfo();
            if (status === 'complete') {
                this.dataInfo = resultData;
            }
        }
    }
};
</script>

<style lang="less" scoped>
.self {
    overflow: hidden;
    // background: url('../../assets/background/backdrop.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height: 400px;
}
</style>
